import { css } from "@emotion/react"
import styled from "@emotion/styled"
import {
  Body,
  breakpoints,
  column,
  GridRow,
  Headline1,
  TextLink,
  typographyStyles,
  vertical,
} from "design-kit"
import React from "react"

import config from "../../config.json"
import {
  WrappedHead,
  WrappedBase,
} from "../shared-components/Shared/WrappedBase"
import Footer from "../shared-components/Footer"
import Navbar from "../shared-components/Navbar"

const Heading2 = styled.h2(typographyStyles.headline3)

const PressContent: React.FunctionComponent = () => (
  <React.Fragment>
    <Navbar sticky />

    <div
      css={css`
        margin: ${vertical.xl} auto ${vertical.m};
      `}
    >
      <GridRow>
        <main
          id="main-content"
          css={css`
            ${column({
              widthDesktop: 8,
              widthMobile: 4,
              widthTablet: 4,
            })}

            > * {
              margin-bottom: ${vertical.xs};
            }
          `}
        >
          <Headline1
            css={css`
              margin: ${vertical.xl} 0 ${vertical.m};

              ${breakpoints.tablet`
                margin-top: ${vertical.xxl};
              `}
            `}
          >
            Habito press &amp; PR
          </Headline1>

          <Heading2>Let us do the talking</Heading2>

          <Body>
            Nothing excites us more than making tech that improves the mortgage
            process and saves people money! If you are having an event, are
            running a press campaign, or need an expert voice on AI, fintech, or
            savvy mortgage saving, we would love to get involved.
          </Body>

          <Heading2>Our media and brand assets</Heading2>

          <Body>
            If you are in need of our media assets, company logo, team photos,
            or branding guidelines, the good news is that you've come to the
            right place! Just click to{" "}
            <TextLink
              href="https://habito-static-public.s3.eu-west-1.amazonaws.com/Habito+Press+Pack.zip"
              text="download our press kit"
            />
            .
          </Body>

          <Heading2>Contact our press team</Heading2>

          <Body>
            For press, events, awards, speaking, or sponsorship opportunities,
            please email{" "}
            <TextLink href="mailto:pr@habito.com" text="pr@habito.com" />.
          </Body>
        </main>
      </GridRow>
    </div>

    <Footer />
  </React.Fragment>
)

export const Head: React.FunctionComponent = () => (
  <WrappedHead
    canonicalUrl="https://www.habito.com/press"
    config={config}
    intercom={true}
    metaDescription="Welcome to our press page. Here you will find the latest articles about Habito, breaking company news, our research and insight reports and, of course, ways to contact us if you are a journalist or event organiser."
    metaTitle="Habito in the press | Habito"
    noIndex={false}
    pageName="press"
  />
)

const PressIndex: React.FunctionComponent = () => (
  <WrappedBase>
    <PressContent />
  </WrappedBase>
)

export default PressIndex
